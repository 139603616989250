<template>
  <v-progress-circular v-if="fetchingGroupData" class="center-center" indeterminate/>
  <v-container v-else class="pa-0">
    <kurcc-inspection-list v-if="isInspectionsTab && groupData" :filter="groupInspectionsFilter"/>
    <kurcc-staff-list v-if="isMembersTab" :key="staffListComponentKey" :filter="[`group_id=${group_id}`]"
                      :group-id="group_id"/>
    <v-dialog :key="addMemberDialogComponentKey" v-model="dialog" fullscreen hide-overlay>
      <template v-slot:activator="{ on, attr }">
        <v-fab-transition>
          <v-btn v-if="isMembersTab && isAdmin" v-bind="attr" v-on="on" :style="{bottom: '72px'}"
                 bottom color="primary" elevation="2" fab fixed right @click.stop="dialog = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
      <kurcc-group-add-member-dialog-content :group-id="group_id" @close-dialog="close"/>
    </v-dialog>
  </v-container>
</template>

<script>
import Group from '@/modules/groups/models/group'

export default {
  name: 'KurccGroupPage',
  props: ['group', 'group_id'],
  components: {
    KurccInspectionList: () => import('@/modules/app/components/KurccInspectionList'),
    KurccStaffList: () => import('@/modules/app/components/KurccStaffList'),
    KurccGroupAddMemberDialogContent: () => import('@/modules/groups/components/KurccGroupAddMemberDialogContent')
  },
  data () {
    return {
      members: [],
      groupData: new Group(),
      fetchingGroupData: false,
      dialog: false,
      staffListComponentKey: 0,
      addMemberDialogComponentKey: 9999
    }
  },
  computed: {
    isInspectionsTab () {
      return this.$route.query.tab === 'inspections'
    },
    isMembersTab () {
      return this.$route.query.tab === 'members'
    },
    isInspector () {
      return this.$auth.user().role.name === 'inspector'
    },
    isAdmin () {
      return this.$auth.user().isAdminInGroup(this.group_id)
    },
    groupInspectionsFilter () {
      const filter = [`group_id=${this.group_id}`]
      if (this.isInspector) {
        filter.push(`inspector_id=${this.$auth.user().id}`)
      }
      return filter
    }
  },
  methods: {
    fetchGroupData () {
      this.fetchingGroupData = true
      this.$store.dispatch('getGroup', { id: this.group_id })
        .then(res => {
          this.groupData = res
        })
        .finally(() => {
          this.fetchingGroupData = false
        })
    },
    close () {
      this.refreshStaffList()
      this.refreshDialogComponent()
      this.dialog = false
    },
    refreshStaffList () {
      this.staffListComponentKey++
    },
    refreshDialogComponent () {
      this.addMemberDialogComponentKey++
    }
  },
  created () {
    if (this.group) {
      this.groupData = this.group
    } else {
      this.fetchGroupData()
    }
  }
}
</script>
